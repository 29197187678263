
  import { Segment } from "@/models/segments";
  import { Suggestion } from "@/models/suggestions";
  import { Visor } from "@/models/visors";
  import { Posting } from "@/models/postings";
  import { Contract } from "@/models/contracts";
  import { CareGiverInstance as CGI } from "@/models/care_giver_instances";
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import { apiDataMorpher, extractRelationshipIds, getRelationshipId } from "@/utils/apiDataHelper"
  import { fullName, resourceUrl } from "@/utils/dataExtractors"
  import { shortDate } from "@/utils/helper"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import SuggestionKeyData from '@/components/shared/SuggestionKeyData.vue'
  import SegmentKeyData from '@/components/shared/SegmentKeyData.vue'
  import CGIKeyData from '@/components/shared/CGIKeyData.vue'
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import Tickets from "@/components/shared/Tickets.vue"
  import Tasks from "@/components/shared/Tasks.vue"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      SuggestionKeyData,
      CGIKeyData,
      SegmentKeyData,
      TicketEdit,
      Tickets,
      Tasks
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const posting = computed<ResourceObject<string, Posting>>(() => root.$store.state.postings.element)
      const contract = computed<ResourceObject<string, Contract>>(() => root.$store.state.contracts.element)
      const newTicketModal = ref()
      const suggestion = computed<ResourceObject<string, Suggestion>>(() => root.$store.state.suggestions.element)
      const ticketIds = ref<string[]>()
      const pageTitle = ref<string>('BK Vorschlag')
      watch(suggestion, (newValue) => {
        const segmentId = getRelationshipId(newValue, 'segment')
        const visorId = getRelationshipId(newValue, 'visor')
        const contractId = getRelationshipId(newValue, 'contract')
        ticketIds.value = ((newValue.relationships?.tickets as RelationshipsWithData)?.data as ResourceIdentifierObject[])?.map((t)=>t.id) || []
        if (segmentId) {
          root.$store.dispatch('segments/get', { id: segmentId })
        }
        if (visorId) {
          root.$store.dispatch('visors/get', { id: visorId })
        }
        if (contractId) {
          pageTitle.value = 'BK Wechselvorschlag'
          root.$store.dispatch('contracts/get', { id: contractId })
        }
      })

      const visor = computed<ResourceObject<string, Visor>>(() => root.$store.state.visors.element)
      watch(visor, (newValue) => {
        const postingId = getRelationshipId(newValue, 'posting')
        if (postingId) {
          root.$store.dispatch('postings/get', { id: postingId })
        }
      })

      const segment = computed<ResourceObject<string, Segment>>(() => root.$store.state.segments.element)
      watch(segment, (newValue) => {
        const cGIId = getRelationshipId(newValue, 'care_giver_instance')
        if (cGIId != '') {
          root.$store.dispatch('care_giver_instances/get', { id: cGIId, queryParams: { include: 'care_giver' } })
        }
      })

      const cGI = computed<ResourceObject<string, CGI>>(() => root.$store.state.care_giver_instances.element)

      const refreshData = () => {
        root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include: 'action_type' } )
        root.$store.dispatch('suggestions/get', { id: props.id })
      }

      refreshData()

      const titleActions = ref([
        newTicketAction(newTicketModal),
        // {
        //   title: 'Bearbeiten',
        //   tooltip: 'Edit',
        //   icon: 'pencil-alt',
        //   onClick: () => alert('Edit')
        // },
      ])

      const ticketSubmitted = () => {
        newTicketModal.value.hide()
        refreshData()
      }

      return {
        refreshData,
        titleActions,
        posting,
        visor,
        suggestion,
        segment,
        cGI,
        fullName,
        resourceUrl,
        shortDate,
        newTicketModal,
        ticketSubmitted,
        ticketIds,
        contract,
        pageTitle
      }
    }
  })
